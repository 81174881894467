const PLAYER_EMBED_SELECTOR = '#watch-player .embed iframe';

export const getEmbedParams = (paramKey) => {
  const embed_url =
    document.querySelector(PLAYER_EMBED_SELECTOR)?.getAttribute('src') || '';
  const embed_url_clean = embed_url.replace(/&amp;/g, '&');
  const searchParams = new URLSearchParams(embed_url_clean);
  return searchParams.get(paramKey);
};

export const getMetaTag = (metatagName) => {
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metatagName) {
      return metas[i].getAttribute('content');
    }
  }

  return '';
};

export const escapeHtml = (unsafe) => {
  if (!unsafe) return unsafe;

  return decodeURIComponent(unsafe)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};

export const getParams = (arg) => {
  const params = window.location.search.replace('?', '');
  return escapeHtml(
    params
      .substr(params.indexOf(arg + '='))
      .split('&')[0]
      .split('=')[1],
  );
};

export const getPlatformEventName = (name, tracking) => {
  const events = tracking.EVENTS;
  switch (name) {
    case events.EVENT_SALE_INITIATED:
      return events.EVENT_AUTHENTICATION_SIGNUP_VIEW;
    case events.EVENT_SALE_COMPLETE:
      return events.EVENT_AUTHENTICATION_CONVERSION;
    case events.EVENT_AUTHENTICATION_LOGIN:
      return events.EVENT_AUTHENTICATION_SIGNIN_VIEW;
    case events.EVENT_SITE_WATCHLIST_ADD:
      return events.EVENT_SITE_WATCHLIST_ADD.replace('site_', '');
    case events.EVENT_SITE_WATCHLIST_REMOVE:
      return events.EVENT_SITE_WATCHLIST_REMOVE.replace('site_', '');
    case events.EVENT_SITE_SEARCH:
      return 'search';
    case events.EVENT_SITE_SEARCH_ITEM_SELECTION:
      return 'searchitemselected';
    default:
      return name.indexOf('site_') > -1 ? 'view' : name;
  }
};

export const getEventProperties = ({
  tracking,
  siteContext,
  additionalProps = {},
  name,
}) => {
  const properties = tracking.PROPERTIES;
  const { collectionID, currentSite, currentUser, page, productID, videoID } =
    siteContext;

  const defaultProperties = {
    [properties.PROPERTY_COLLECTION_ID]: collectionID,
    [properties.PROPERTY_DEVICE]: 'web',
    [properties.PROPERTY_DEVICE_ID]: null,
    [properties.PROPERTY_NAME]: name || page?.PROPERTIES?.NAME || 'view',
    [properties.PROPERTY_PLATFORM]: 'web',
    [properties.PROPERTY_PLATFORM_ID]: null,
    [properties.PROPERTY_PLATFORM_VERSION]: null,
    [properties.PROPERTY_PRODUCT_ID]: productID,
    [properties.PROPERTY_REFERRER]: document.referrer,
    [properties.PROPERTY_SITE_ID]: currentSite.id,
    [properties.PROPERTY_TIMESTAMP]: new Date().getTime(),
    [properties.PROPERTY_TYPE]: 'platform',
    [properties.PROPERTY_URL]: window.location.href,
    [properties.PROPERTY_USER_EMAIL]: currentUser.email,
    [properties.PROPERTY_USER_ID]: currentUser.id,
    [properties.PROPERTY_VIDEO_ID]: videoID,
    [properties.PROPERTY_VIEW]: getPageViewName(siteContext),
    [properties.PROPERTY_SESSION_ID]: tracking.SESSION,
  };

  let _additionalProps = additionalProps;

  if (additionalProps?.type === 'video') {
    _additionalProps = {
      ...additionalProps,
      collection_id: null,
      product_id: null,
    };
  }
  if (
    ['category', 'collection', 'movie', 'playlist', 'series'].includes(
      additionalProps?.type,
    )
  ) {
    _additionalProps = {
      ...additionalProps,
      video_id: null,
      product_id: null,
    };
  }

  if (additionalProps?.type === 'product') {
    _additionalProps = {
      ...additionalProps,
      collection_id: null,
      video_id: null,
    };
  }

  return {
    ...defaultProperties,
    ..._additionalProps,
    [properties.PROPERTY_TYPE]: 'platform',
    [properties.PROPERTY_URL]: additionalProps.url || window.location.href,
  };
};

export const getV2EventName = (name, tracking = {}) => {
  if (tracking.DEPRECATED_EVENTS?.includes(name)) {
    return '';
  }
  if (tracking.UPDATED_EVENTS?.[name]) {
    return tracking.UPDATED_EVENTS[name];
  }
  return name;
};

const PAGE_VIEW_MAPPING = {
  settings: {
    '/settings/billing': 'settings_billing',
    '/settings/emails': 'settings_emails',
    '/settings/info': 'settings_info',
    '/settings/purchases': 'settings_purchases',
  },
  others: {
    tos: 'terms_of_service',
    unavailable: 'unavailable_geoblocked',
  },
};

export const getPageViewName = (siteContext) => {
  const { activeView } = siteContext;
  const pathname = window.location.pathname;
  const pathnameEnd = pathname.substring(pathname.lastIndexOf('/') + 1);
  /**
   * When email link is being sent
   */
  if (pathnameEnd === 'send_login_email') {
    return 'login_email_sent';
  }
  /**
   * For user settings pages
   */
  if (PAGE_VIEW_MAPPING.settings[pathname]) {
    return PAGE_VIEW_MAPPING.settings[pathname];
  }

  // Others
  if (PAGE_VIEW_MAPPING.others[activeView]) {
    return PAGE_VIEW_MAPPING.others[activeView];
  }
  return activeView.replace(/-/g, '_');
};

export const getVideoProperties = (options, tracking, name) => {
  const { collectionID, currentSite, video } = options.siteContext;
  const eventProperties = getEventProperties({
    tracking,
    siteContext: options.siteContext,
    additionalProps: {
      current_src: video.current_src,
      current_type: video.current_type,
      duration: video.duration,
      is_chromecast: video.is_chromecast ? 1 : 0,
      is_drm: video.is_drm ? 1 : 0,
      is_fullscreen: video.is_fullscreen ? 1 : 0,
      is_live: getEmbedParams('live') !== '0' ? 1 : 0,
      is_trailer: video.is_trailer ? 1 : 0,
      platform_id: null,
      seconds: currentSite.segment_heartbeat_frequency,
      timecode: video.current_time,
      type: 'video',
    },
    name,
  });
  return {
    ...eventProperties,
    collection_id: collectionID,
    type: 'video',
    view: undefined,
  };
};

export const loadIframe = (url) => {
  const iframe = document.createElement('iframe');
  iframe.setAttribute('width', '1');
  iframe.setAttribute('height', '1');
  iframe.frameBorder = 0;
  iframe.src = url;
  iframe.style.display = 'none';

  document.body.appendChild(iframe);
};

export const loadScript = (url) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  script.async = true;
  document.getElementsByTagName('head')[0].appendChild(script);
};

export const getCookie = (cookieName) => {
  const cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();
    if (cookie.indexOf(cookieName + '=') === 0) {
      return cookie.substring(cookieName.length + 1);
    }
  }
  return null;
};
