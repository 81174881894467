import Cookies from 'js-cookie';

import EventQueue from './EventQueue';
import { getCookie, loadIframe, loadScript } from './utils';

const pageViews = (options) => {
  const { currentUser } = options.siteContext;

  return {
    // blackandsexy.vhx.tv - 9867
    9867: () => {
      // FLOODLIGHT
      loadIframe(
        'https://5039270.fls.doubleclick.net/activityi;src=5039270;type=black00;cat=blksxybp;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=1?',
      );
    },
    // vettv.vhx.tv - 22104
    22104: () => {
      // KLAVIYO cart tracking
      const _learnq = _learnq || [];
      if (currentUser.email) {
        _learnq.push(['identify', { $email: currentUser.email }]);
        _learnq.push(['track', 'Started Checkout', {}]);
      }

      new Image().src =
        'https://images.inthe.net/api/GetImage?type=tp&ad_act_type=7d19a336-f3b3-408e-a6b3-0a98998f0b44&ad_id=ea4cf077-de7b-4ece-9505-68a37aba55a3&vender=VetTV_Checkout';
    },
    // taebotoday1.vhx.tv - 27600
    27600: () => {
      new Image().src = '//in.getclicky.com/101014264ns.gif';
    },
    // hillsongchannel.vhx.tv - 30697
    30697: () => {
      // adnxs PAGE VIEW px
      new Image().src = 'https://secure.adnxs.com/px?id=934372&t=1';
    },
    // corepoweryogaondemand.vhx.tv - 44887
    44887: () => {
      /* Start FastG8 Pixel Tag */
      const url =
        'https://u.fg8dgt.com/pixel?type=js&id=434&cb=' +
        Math.floor(Math.random() * 100000000);
      loadScript(url);
      /* end fastg8 */
    },
    // dogtv2.vhx.tv - 105720
    105720: () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://cactusmedia.com/js/p-06032020.js';
      document.body.parentNode.insertBefore(script, document.body.nextSibling);

      const tapjoy_script = document.createElement('script');
      tapjoy_script.type = 'text/javascript';
      tapjoy_script.src = 'https://www.tp88trk.com/scripts/sdk/everflow.js';
      document.body.parentNode.insertBefore(
        tapjoy_script,
        document.body.nextSibling,
      );

      if (window.EF) {
        window.EF.click({
          offer_id: window.EF.urlParameter('oid'),
          sub1: window.EF.urlParameter('sub1'),
          sub2: window.EF.urlParameter('sub2'),
          sub3: window.EF.urlParameter('sub3'),
          sub4: window.EF.urlParameter('sub4'),
          sub5: window.EF.urlParameter('sub5'),
          uid: window.EF.urlParameter('uid'),
          transaction_id: window.EF.urlParameter('tid'),
        });
      }
    },
    // urbanflixtv.vhx.tv - 92559
    92559: () => {
      new Image().src =
        'https://tags.w55c.net/rs?id=e23117610b84432cba12d0a1135ddecc&t=cart&sku=' +
        window.VHX.data.product.sku;
    },
  };
};

const conversions = (options) => {
  const {
    siteContext: { currentUser },
    transaction,
  } = options;

  return {
    // gorging.vhx.tv
    1251: () => {
      new Image().src =
        'https://secure.adnxs.com/px?id=192571&order_id=[ORDER_ID]&value=[REVENUE]&t=2';
    },
    // watchmh-rth.vhx.tv
    125888: () => {
      const insightAdPixel = new Image();
      insightAdPixel.src =
        'https://insight.adsrvr.org/track/pxl/?adv=b25nneq&ct=0:8cispjt&fmt=3';
      insightAdPixel.width = '1';
      insightAdPixel.height = '1';
      insightAdPixel.style = 'border-style:none';
    },
    // discgolfprotour.vhx.tv
    90968: () => {
      const segmentTag = new Image();
      segmentTag.src =
        'https://segment.prod.bidr.io/associate-segment?buzz_key=dsp&segment_key=dsp-6448&value=';
      segmentTag.width = '0';
      segmentTag.height = '0';

      const conversionTag = new Image();
      conversionTag.src =
        'https://cnv.event.prod.bidr.io/log/cnv?tag_id=191&buzz_key=dsp&value=&segment_key=dsp-6448&account_id=39&order=1&ord=1';
      conversionTag.width = '0';
      conversionTag.height = '0';
    },
    // redbullmediahouse.vhx.tv - 4004
    4004: () => {
      // only fire for On Any Sunday package purchase
      if (transaction.product_id === 3865) {
        // The Search Agency tracker / DoubleClick tracker
        const url = `https://4338601.fls.doubleclick.net/activityi;src=4338601;type=purch1;cat=wpmh01;qty=1;cost=${transaction.amount.format};ord=${transaction.id}?`;
        loadIframe(url);
      } // end on any sunday
    },
    // againstthesun.vhx.tv - 7218
    7218: () => {
      // doubleclick
      const axel = Math.random() + '';
      const a = axel * 10000000000000;
      const url = `https://4533508.fls.doubleclick.net/activityi;src=4533508;type=afc-ats;cat=atsconfm;ord=${a}?`;
      loadIframe(url);

      window['friendbuy'] = window['friendbuy'] || [];
      window['friendbuy'].push(['site', 'site-b6444db1-www.againstthesun.com']);
      window['friendbuy'].push([
        'track',
        'customer',
        {
          id: transaction.user_id, //INSERT CUSTOMER ID PARAMETER
          email: transaction.user_email, //INSERT CUSTOMER EMAIL PARAMETER
        },
      ]);
      window['friendbuy'].push([
        'track',
        'order',
        {
          id: transaction.id, //REPLACE WITH YOUR ORDER ID
          amount: transaction.amount.cents / 100.0, //REPLACE WITH YOUR ORDER AMOUNT
        },
      ]);

      loadScript('https://djnf6e5yyirys.cloudfront.net/js/friendbuy.min.js');
    },
    // realpars.vhx.tv - 7247
    7247: () => {
      new Image().src =
        'https://dc.ads.linkedin.com/collect/?pid=321466&conversionId=365345&fmt=gif';
    },
    // manilowtv.vhx.tv - 11725
    11725: () => {
      var purchaseAmount = transaction.amount.cents / 100.0;
      var orderKey = transaction.id;

      window.tbEvents = [];
      window.tbEvents.push({
        eventName: 'purchase',
        eventAmount: purchaseAmount,
        orderKey: orderKey,
      });

      new Image().src = `https://tradablebits.com/pixels/7177498/event?event_name=purchase&event_amount=${purchaseAmount}&order_key=${orderKey}`;
    },
    // arrow2.vhx.tv
    108459: () => {
      var orderId = transaction.id;
      var revenue = transaction.amount.cents / 100.0;

      var _qevents = _qevents || [];
      _qevents.push({
        qacct: 'p-2UPkbcw6Ne-Tf',
        labels: '_fp.event.Player Purchase Confirmation',
        orderid: `${orderId}`,
        revenue: `${revenue}`,
        event: 'refresh',
      });
    },
    // mhzchoice.vhx.tv - 18790
    18790: () => {
      // attribution
      if (window.Attribution) {
        var anonAttribution = '';
        // determine if we have a value already
        if (Cookies.get('anonymousAttribution')) {
          anonAttribution = Cookies.get('anonymousAttribution');
        } else if (context.getParams('anon')) {
          anonAttribution = context.getParams('anon');
        }
        window.Attribution.alias(transaction.user_id, anonAttribution);

        window.Attribution.identify(transaction.user_id, {
          name: transaction.user_name,
          email: transaction.user_email,
          product_id: transaction.product_id,
          createdAt: new Date(),
        });
        window.Attribution.track('Completed Purchase', {
          revenue: transaction.amount.cents / 100.0,
        });
      }

      if (window.mbsy) {
        window.mbsy.identify({ email: currentUser.email });
        window.mbsy.track({ revenue: transaction.amount.cents / 100.0 });
      }

      const doubleClickAdPixel = new Image();
      doubleClickAdPixel.src = `https://ad.doubleclick.net/ddm/activity/src=11893105;type=mhzsa0;cat=start0;qty=1;cost={transaction.amount.format};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=;gdpr_consent=;ord=${transaction.id}?`;
      doubleClickAdPixel.width = '1';
      doubleClickAdPixel.height = '1';
      if (typeof gtag === 'function') {
        window.gtag('event', 'purchase', {
          send_to: 'DC-11893105/mhzsa0/start0+transactions',
          value: transaction.amount.cents / 100.0,
          transaction_id: transaction.id,
        });
      }
    },
    // bodyrock.vhx.tv - 20255
    20255: () => {
      // clickmeter.com
      new Image().src = `https://www.clickmeter.com/conversion.aspx?id=B3F97E49EE2C40CE82091CF0A787F0B6&val=${
        transaction.amount.cents / 100.0
      }&param=${transaction.product_name}&com=0.00&comperc=0.00`;
    },
    // vettv.vhx.tv - 22104
    22104: () => {
      const transaction_amount = transaction.amount.cents / 100.0;

      // KLAVIYO cart tracking
      const transaction_time = new Date();
      const _learnq = _learnq || [];
      _learnq.push(['identify', { $email: transaction.user_email }]);
      _learnq.push([
        'track',
        'Placed Order',
        {
          customer_properties: {
            $email: transaction.user_email,
            $first_name: transaction.user_name,
          },
          properties: {
            $event_id: transaction.id,
            $value: transaction_amount,
            ItemNames: ['Veteran Television Subscription'],
          },
          Items: [
            {
              SKU: 'VETTV_SVOD',
              ProductName: 'Veteran Television Subscription',
              Quantity: 1,
            },
          ],
          time: transaction_time,
        },
      ]);

      // Convert Experiences tracking
      window._conv_q = window._conv_q || [];
      window._conv_q.push([
        'pushRevenue',
        transaction_amount,
        '1',
        '100312760',
      ]);
    },
    // ashleyborden.vhx.tv - 24862
    24862: () => {
      // OSI Affiliate Tracking
      const url = `https://ashleyborden.ositracker.com/sales/salejs/amount:${
        transaction.amount.cents / 100.0
      }/transaction:${transaction.id}/firstname:FIRST_NAME/lastname:LAST_NAME`;
      loadIframe(url);
    },
    // taebotoday1.vhx.tv - 27600
    27600: () => {
      new Image().src = '//in.getclicky.com/101014264ns.gif';
    },
    // alihuda.vhx.tv - 28998
    28998: () => {
      new Image().src =
        'https://servedbyadbutler.com/convtrack.spark?ID=170140';
      // fire off adwords CONVERSION
      if (typeof gtag === 'function') {
        window.gtag('event', 'conversion', {
          send_to: 'AW-842820571/CggmCKrCxHoQ29fxkQM',
          transaction_id: transaction.id,
        });
      }
    },
    // hillsongchannel.vhx.tv - 30697
    30697: () => {
      new Image().src = 'https://secure.adnxs.com/px?id=934366&t=1';
    },
    // weedandwhiskeytv.vhx.tv - 31496
    31496: () => {
      const weedandwhiskeyRefersionTimeout = setInterval(function () {
        if (typeof _refersion === 'function') {
          clearInterval(weedandwhiskeyRefersionTimeout);
          window._refersion(function () {
            window._rfsn._addTrans({
              order_id: transaction.id,
              currency_code: 'USD',
            });

            window._rfsn._addCustomer({
              first_name: transaction.user_name,
              email: transaction.user_email,
            });

            window._rfsn._addItem({
              sku: transaction.product_id,
              quantity: '1',
              price: transaction.amount.cents / 100.0,
            });

            window._rfsn._sendConversion();
          });
        }
      }, 500);
    },
    // kirastokesfit.vhx.tv - 88695
    88695: () => {
      var amount = transaction.is_free_trial
        ? 0
        : transaction.amount.cents / 100.0;
      const kirastokesfitRefersionTimeout = setInterval(function () {
        if (typeof _refersion === 'function') {
          clearInterval(kirastokesfitRefersionTimeout);
          window._refersion(function () {
            window._rfsn._addTrans({
              order_id: transaction.id,
              currency_code: 'USD',
            });

            window._rfsn._addCustomer({
              first_name: transaction.user_name,
              email: transaction.user_email,
            });

            window._rfsn._addItem({
              sku: transaction.product_id,
              quantity: '1',
              price: amount,
            });

            window._rfsn._sendConversion();
          });
        }
      }, 500);
    },
    // memory-lane.vhx.tv - 38126
    38126: () => {
      var amount = transaction.is_free_trial
        ? 0
        : transaction.amount.cents / 100.0;
      const memoryLaneRefersionTimeout = setInterval(function () {
        if (typeof _refersion === 'function') {
          clearInterval(memoryLaneRefersionTimeout);
          window._refersion(function () {
            window._rfsn._addTrans({
              order_id: transaction.id,
              currency_code: 'USD',
              discount_code: transaction.promo_code,
            });

            window._rfsn._addCustomer({
              first_name: transaction.user_name,
              email: transaction.user_email,
            });

            window._rfsn._addItem({
              sku: transaction.product_id,
              quantity: '1',
              price: amount,
            });

            window._rfsn._sendConversion();
          });
        }
      }, 500);
    },
    // vooks2.vhx.tv - 32557
    32557: () => {
      if (window.mbsy) {
        window.mbsy.identify({ email: currentUser.email });
        window.mbsy.track({ revenue: transaction.amount.cents / 100.0 });
      }
    },
    // upfaithandfamily.vhx.tv - 33064
    33064: () => {
      // Google Tag Manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'signup-success',
      });
    },
    // corepoweryogaondemand.vhx.tv - 44887
    44887: () => {
      if (typeof gtag === 'function') {
        window.dataLayer = window.dataLayer || [];
        window.gtag('event', 'conversion', {
          send_to: 'AW-865543806/CWQxCImeuIYBEP7M3JwD',
        });
      }
    },
    // yippee.vhx.tv - 61429
    61429: () => {
      var customerID = localStorage.getItem('ajs_user_traits');
      var custEmail = JSON.parse(customerID)?.email;
      if (custEmail.length) {
        var cartID = btoa(custEmail);
        window._refersion(function () {
          window._rfsn._addCart(cartID);
        });
      } else {
        window._refersion();
      }

      var userId = transaction.user_id;
      var externalidentifier = userId.toString();

      var rrConversionObject = {
        debug: 'false',
        parameters: {
          firstname: transaction.user_name,
          email: transaction.user_email,
          externalidentifier: externalidentifier,
          amount: transaction.amount.cents,
        },
      };

      var rrSpaceObject = window.rrSpace;
      rrSpaceObject.executeEvent('conversion', rrConversionObject);

      // dataLayer
      if (typeof gtag === 'function') {
        const orderSubtotal = transaction.amount.cents / 100.0;
        const thedate = new Date();
        window.dataLayer = window.dataLayer || [];
        window.gtag('event', 'transaction', {
          currency: transaction.amount.currency,
          transaction_id: transaction.id,
          value: orderSubtotal,
          category: transaction.purchase_type,
          orderPromoCode: transaction.promo_code,
          date: thedate,
          items: [
            {
              item_id: transaction.product_id,
              item_name: transaction.product_name,
              item_category: transaction.purchase_type,
              value: orderSubtotal,
              quantity: transaction.units,
            },
          ],
        });
        window.gtag('customer', {
          firstname: transaction.user_name,
          email: transaction.user_email,
          id: transaction.user_id,
        });
      }
    },
    // mysigningtime.vhx.tv - 87218
    87218: () => {
      if (window.mbsy) {
        window.mbsy.identify({ email: currentUser.email });
        window.mbsy.track({ revenue: transaction.amount.cents / 100.0 });
      }

      var amount = transaction.is_free_trial
        ? 0
        : transaction.amount.cents / 100.0;

      const mysigningtimeRefersionTimeout = setInterval(function () {
        if (typeof _refersion === 'function') {
          clearInterval(mysigningtimeRefersionTimeout);
          window._refersion(function () {
            window._rfsn._addTrans({
              order_id: transaction.id,
              currency_code: 'USD',
            });

            window._rfsn._addCustomer({
              first_name: transaction.user_name,
              email: transaction.user_email,
            });

            window._rfsn._addItem({
              sku: transaction.product_id,
              quantity: '1',
              price: amount,
            });

            window._rfsn._sendConversion();
          });
        }
      }, 500);
    },
    // modernom.vhx.tv - 138067
    138067: () => {
      var amount = transaction.is_free_trial
        ? 0
        : transaction.amount.cents / 100.0;

      const modernomRefersionTimeout = setInterval(function () {
        if (typeof _refersion === 'function') {
          clearInterval(modernomRefersionTimeout);
          window._refersion(function () {
            window._rfsn._addTrans({
              order_id: transaction.id,
              currency_code: 'USD',
            });

            window._rfsn._addCustomer({
              first_name: transaction.user_name,
              email: transaction.user_email,
            });

            window._rfsn._addItem({
              sku: transaction.product_id,
              quantity: '1',
              price: amount,
            });

            window._rfsn._sendConversion();
          });
        }
      }, 500);
    },
    // jbsalesondemand.vhx.tv - 89479
    89479: () => {
      if (typeof growsumo === 'object') {
        window.growsumo.data.name = transaction.user_name;
        window.growsumo.data.email = transaction.user_email;
        window.growsumo.data.customer_key = transaction.user_email;
        window.growsumo.createSignup();
      }
    },
    // urbanflixtv.vhx.tv - 92559
    92559: () => {
      var price = transaction.amount.cents / 100.0;

      new Image().src = `https://tags.w55c.net/rs?id=2f3de766ba814e09a00ac96795b571b1&t=checkout&tx=${transaction.id}&sku=${transaction.product_sku}price=${price}`;
      if (typeof gtag === 'function') {
        window.gtag('event', 'conversion', {
          send_to: 'AW-653956162/ZnpDCMO3t80BEMKo6rcC',
          value: price,
          currency: transaction.amount.currency,
        });
      }
    },
    // streamisrael.vhx.tv - 103125
    103125: () => {
      var price = transaction.amount.cents / 100.0;

      if (typeof gtag === 'function') {
        window.gtag('event', 'conversion', {
          send_to: 'AW-499481611/0seWCISQ7-QCEIv4le4B',
          value: price,
          currency: transaction.amount.currency,
        });
      }
    },
    // thesculptsociety4.vhx.tv - 84150
    84150: () => {
      window.ire(
        'trackConversion',
        23208,
        {
          orderId: transaction.id,
          customerId: transaction.user_id,
          customerEmail: '',
          currencyCode: transaction.amount.currency,
          orderPromoCode: transaction.promo_code,
          items: [
            {
              subTotal: transaction.amount.cents / 100.0,
              category: transaction.subscription_frequency,
              sku: transaction.product_sku,
              quantity: 1,
            },
          ],
        },
        { verifySiteDefinitionMatch: true },
      );

      if (window.Attribution) {
        window.Attribution.identify({ email: transaction.user_email });

        window.Attribution.track('trackConversion', {
          customer_id: transaction.user_id,
          email: transaction.user_email,
          revenue: transaction.amount.cents / 100.0,
          currency: transaction.amount.currency,
          promo_code: transaction.promo_code,
          payment_method_type: transaction.payment_method_type,
        });
      }
    },
    // theclass.vhx.tv - 71759
    71759: () => {
      if (transaction.purchase_type === 'subscription') {
        window.ire(
          'trackConversion',
          24992,
          {
            orderId: transaction.id,
            customerId: transaction.user_id,
            customerEmail: '',
            currencyCode: transaction.amount.currency,
            orderPromoCode: transaction.promo_code,
          },
          { verifySiteDefinitionMatch: true },
        );
      } else if (transaction.purchase_type === 'purchase') {
        window.ire(
          'trackConversion',
          24994,
          {
            orderId: transaction.id,
            customerId: transaction.user_id,
            customerEmail: '',
            currencyCode: transaction.amount.currency,
            orderPromoCode: transaction.promo_code,
            items: [
              {
                subTotal: transaction.amount.cents / 100.0,
                category: transaction.purchase_type,
                sku: transaction.product_sku,
                quantity: 1,
              },
            ],
          },
          { verifySiteDefinitionMatch: true },
        );
      }
    },
    // dogtv2.vhx.tv - 105720
    105720: () => {
      new Image().src = 'https://cactusmedia.com/js/pe1v2-07022020.js';
      new Image().src =
        'https://tag.simpli.fi/sifitag/5335f080-72e9-0139-af88-06a60fe5fe77';

      // GWM pixel
      const url = 'https://ahghe.com/v/1/c/p/1/o/28044';
      loadIframe(url);

      // site scout pixel
      const siteScoutPixel = new Image();
      siteScoutPixel.src = 'https://pixel.sitescout.com/iap/b40da9a9030f8c49';
      siteScoutPixel.width = '1';
      siteScoutPixel.height = '1';
      siteScoutPixel.style = 'position: absolute';
      siteScoutPixel.left = '-150px';

      const orderSubtotal = transaction.amount.cents / 100.0;
      const shareASalePixel = new Image();
      shareASalePixel.src = `https://www.shareasale.com/sale.cfm?tracking=${transaction.id}&amount=${orderSubtotal}&merchantID=102817&transtype=sale&skulist=${transaction.product_sku}&pricelist=${orderSubtotal}&quantitylist=${transaction.units}&currency=${transaction.amount.currency}`;
      shareASalePixel.height = '1';
      shareASalePixel.width = '1';

      const mntn_script = document.createElement('script');
      mntn_script.innerHTML = `(function(){var x=null,p,q,m, o="32450", l=${transaction.id}, i=${orderSubtotal}, c="", k="",
g="", j="", u="", shadditional="";
try{p=top.document.referer!==""?encodeURIComponent(top.document.referrer.substring(0,512)):""}catch(n){p=document.referrer!==null?document.referrer.toString().substring(0,512):""}try{q=window&&window.top&&document.location&&window.top.location===document.location?document.location:window&&window.top&&window.top.location&&""!==window.top.location?window.top.location:document.location}catch(b){q=document.location}try{m=parent.location.href!==""?encodeURIComponent(parent.location.href.toString().substring(0,512)):""}catch(z){try{m=q!==null?encodeURIComponent(q.toString().substring(0,512)):""}catch(h){m=""}}var
A,y=document.createElement("script"),w=null,v=document.getElementsByTagName("script"),t=Number(v.length)-1,r=document.getElementsByTagName("script")[t];if(typeof
A==="undefined"){A=Math.floor(Math.random()*100000000000000000)}w="dx.mountain.com/spx?conv=1&shaid="+o+"&tdr="+p+"&plh="+m+"&cb="+A+"&shoid="+l+"&shoamt="+i+"&shocur="+c+"&shopid="+k+"&shoq="+g+"&shoup="+j+"&shpil="+u+shadditional;y.type="text/javascript";y.src=("https:"===document.location.protocol?"https://":"http://")+w;r.parentNode.insertBefore(y,r)}());`;
      document.body.appendChild(mntn_script);

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.defer = 'defer';
      script.src = 'https://www.dwin1.com/19038.js';
      document.body.appendChild(script);

      //adroll pixel
      const adrollPixel = new Image();
      adrollPixel.src =
        '//d.adroll.com/ipixel/CU7EEK6ZURBG7P6TZDFSGI/KREM3ZUWSRGDBBHFISXS5L?name=34e9ca5c';
      adrollPixel.height = '1';
      adrollPixel.width = '1';

      new Image().src = 'https://www.tp88trk.com/scripts/sdk/everflow.js';
      window.EF.conversion({
        aid: 293,
      });

      //unbounced pixel
      window._ubaq = window._ubaq || [];
      window._ubaq.push(['trackGoal', 'convert']);

      const unbounced_script = document.createElement('script');
      unbounced_script.type = 'text/javascript';
      unbounced_script.src = 'https://d3pkntwtp2ukl5.cloudfront.net/uba.js';
      document.body.appendChild(unbounced_script);

      window.ndp('track', 'CONVERSION');
      const nextDoorPixel = new Image();
      nextDoorPixel.src =
        'https://flask.nextdoor.com/pixel?pid=dd5a87eb-83d4-4f10-bf31-41d427b72590&ev=CONVERSION&noscript=1';
      nextDoorPixel.height = '1';
      nextDoorPixel.width = '1';

      // Out of Home Pixel
      const outOfHomeExtensionPixel = new Image();
      outOfHomeExtensionPixel.src =
        'https://pixel.tvsciapi.com/pixel?l=tvscientific-pix-o-923a385e-4205-4412-af3a-2440df5116c1';
      outOfHomeExtensionPixel.width = '0';
      outOfHomeExtensionPixel.height = '0';
      outOfHomeExtensionPixel.style = 'display: none';

      // Order Purchase Pixel
      const pix_script = document.createElement('script');
      pix_script.innerHTML = `(function () {var p, s, d, w;d = document;w = window.location;p = d.createElement("IMG");s =
w.protocol + "//pix.pub/t.png?&t=" + (new Date).getTime() +
"&l=tvscientific-pix-o-923a385e-4205-4412-af3a-2440df5116c1&u3=" + encodeURIComponent(w.href) +
"&u1=complete_purchase&u2=${orderSubtotal}&u4=${transaction.id}&u5=";p.setAttribute("src", s);p.setAttribute("height",
"1");p.setAttribute("width", "1");d.body.appendChild(p);})();`;
      document.body.appendChild(pix_script);

      // adelphic
      if (typeof window.AdelphicUniversalPixel === 'function') {
        new window.AdelphicUniversalPixel(
          105431,
          'https://ad.ipredictive.com/d/track/event',
          {
            tn: transaction.id,
            val: transaction.amount.cents / 100.0,
            ps: '0',
          },
        ).fire();
      }

      // cj affiliate conversion tracking
      const cjEventCookie = getCookie('cjevent');

      var cjPixelSrc =
        'https://www.emjcd.com/u?CID=1565528&TYPE=437766&METHOD=IMG';
      if (cjEventCookie) {
        cjPixelSrc += '&CJEVENT=' + cjEventCookie;
      }
      cjPixelSrc += '&OID=' + transaction.id;
      cjPixelSrc += '&CURRENCY=' + transaction.amount.currency;
      if (transaction.promo_code) {
        cjPixelSrc += '&COUPON=' + transaction.promo_code;
      }
      cjPixelSrc += '&AMOUNT=' + transaction.amount.cents / 100.0;

      const cjConversionPixel = new Image();
      cjConversionPixel.src = cjPixelSrc;
    },
    // naturalpilates.vhx.tv - 95172
    95172: () => {
      new Image().src = `https://mistingsmarket.com/p.ashx?o=2487&e=585&fjs&t=${transaction.id}`;
    },
    // daybreaker.vhx.tv = 113239
    113239: () => {
      const orderSubtotal = transaction.amount.cents / 100.0;
      const shareASalePixel = new Image();
      shareASalePixel.src = `https://www.shareasale.com/sale.cfm?tracking=${transaction.id}&amount=${orderSubtotal}&merchantID=107127&transtype=sale&skulist=${transaction.product_sku}&pricelist=${orderSubtotal}&quantitylist=${transaction.units}&currency=${transaction.amount.currency}`;
      shareASalePixel.height = '1';
      shareASalePixel.width = '1';

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.defer = 'defer';
      script.src = 'https://www.dwin1.com/19038.js';
      document.body.appendChild(script);

      new Image().src = 'https://www.dlap2tk.com/scripts/sdk/everflow.js';
      window.EF.conversion({
        aid: 1,
        amount: orderSubtotal,
        email: transaction.user_email,
      });
    },
    // thenessdigital.vhx.tv - 91041
    91041: () => {
      const orderSubtotal = transaction.amount.cents / 100.0;
      const shareASalePixel = new Image();
      shareASalePixel.src = `https://www.shareasale.com/sale.cfm?tracking=${transaction.id}&amount=${orderSubtotal}&merchantID=1202861&transtype=sale&skulist=${transaction.product_sku}&pricelist=${orderSubtotal}&quantitylist=${transaction.units}&currency=${transaction.amount.currency}`;
      shareASalePixel.height = '1';
      shareASalePixel.width = '1';

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.defer = 'defer';
      script.src = 'https://www.dwin1.com/19038.js';
      document.body.appendChild(script);
    },
    // elleondemandinc.vhx.tv - 107227
    107227: () => {
      if (window.pintrk) {
        window.pintrk('track', 'checkout', { order_quantity: 1 });
      }
    },
    // topic.vhx.tv - 129746
    129746: () => {
      const channel = transaction.referrer;
      const currencyCode = transaction.amount.currency;
      const discountCode = transaction.promo_code;
      const isTestMode = 0;
      const orderRef = transaction.id;
      const orderSubtotal = transaction.amount.cents / 100.0;
      const parts = `DEFAULT:${orderSubtotal}`;

      var AWIN = {};
      AWIN.Tracking = {};
      AWIN.Tracking.Sale = {};
      AWIN.Tracking.Sale.amount = orderSubtotal;
      AWIN.Tracking.Sale.channel = channel;
      AWIN.Tracking.Sale.orderRef = orderRef;
      AWIN.Tracking.Sale.parts = parts;
      AWIN.Tracking.Sale.currency = currencyCode;
      AWIN.Tracking.Sale.voucher = discountCode;
      AWIN.Tracking.Sale.test = isTestMode;

      var awMastertag = document.createElement('script');
      awMastertag.src = 'https://www.dwin1.com/19522.js';
      awMastertag.type = 'text/javascript';
      awMastertag.setAttribute('defer', 'defer');
      document.body.appendChild(awMastertag);

      new Image().src = `https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=19522&amount=${orderSubtotal}&cr=${currencyCode}&ref=${orderRef}&parts=${parts}&vc=${discountCode}&ch=${channel}&testmode=${isTestMode}`;
    },
    // dirtvision.vhx.tv - 139215
    139215: () => {
      const quantity = transaction.amount.cents / 100.0;
      const confirmation_code = transaction.id;
      const purchase_date = new Date()
        .toISOString()
        .replace(/T/, '-')
        .replace(/\..+/, '')
        .replace(/:/g, '-');
      if (transaction.subscription_frequency === 'monthly') {
        new Image().src = `https://pm.geniusmonkey.com/gm.png?id=226593869&quantity=${quantity}&confirmation_code=${confirmation_code}&purchase_date=${purchase_date}`;
      } else if (transaction.subscription_frequency === 'yearly') {
        new Image().src = `https://pm.geniusmonkey.com/gm.png?id=226593868&quantity=${quantity}&confirmation_code=${confirmation_code}&purchase_date=${purchase_date}`;
      }
    },
  };
};

const SiteCustomJSTracking = (tracking) => {
  const trackEvent = (options) => {
    try {
      pageViews(options)[options.siteContext.currentSite?.id]?.();
    } catch (e) {
      console.log(e);
    }
  };

  const trackSale = (options) => {
    try {
      conversions(options)[options.siteContext.currentSite?.id]?.();
    } catch (e) {
      console.log(e);
    }
  };

  const subscribeEvents = () => {
    EventQueue.subscribe('site_checkout_page_open', trackEvent);
    EventQueue.subscribe(
      tracking.EVENTS.EVENT_AUTHENTICATION_CONVERSION,
      trackSale,
    );
    EventQueue.subscribe(
      tracking.EVENTS.EVENT_AUTHENTICATION_PURCHASE_COMPLETED,
      trackSale,
    );
  };

  subscribeEvents();
};

export default SiteCustomJSTracking;
