export const getRecaptchaToken = async (enabled: boolean) => {
  if (enabled) {
    try {
      return await executeRecaptcha();
    } catch (e) {
      // intentionally left blank
    }
  }
  return null;
};

const executeRecaptcha = () => {
  return new Promise((resolve, reject) => {
    window.grecaptcha.enterprise.ready(() => {
      try {
        window.grecaptcha.enterprise
          .execute(window.ott_recaptcha.SITE_KEY, {
            action: window.ott_recaptcha.ACTIONS.SIGNUP,
          })
          .then((token) => {
            resolve(token);
          });
      } catch (e) {
        reject();
      }
    });
  });
};
