import foundReducer from 'found/lib/foundReducer';

import CheckoutReducer from '~/apps/Customer/pages/checkout/reducers';

import GlobalSiteReducer from './global.reducer';

export default {
  found: foundReducer,
  global: GlobalSiteReducer,
  checkout: CheckoutReducer,
};
