import EventQueue from './tracking/EventQueue';
import Facebook from './tracking/Facebook';
import Google from './tracking/Google';
import GoogleAnalytics4 from './tracking/GoogleAnalytics4';
import Platform from './tracking/Platform';
import Segment from './tracking/Segment';
import SiteContext from './tracking/SiteContext';
import SiteCustomJSTracking from './tracking/SiteCustomJSTracking';
import Twitter from './tracking/Twitter';
import { getV2EventName } from './tracking/utils';

export default class StatsAdapter {
  constructor(props) {
    this.tracking = props.tracking;
    this.siteContext = new SiteContext(props);
    this._init();
  }

  _init() {
    [
      Facebook,
      Google,
      GoogleAnalytics4,
      Platform,
      Segment,
      Twitter,
      SiteCustomJSTracking,
    ].forEach((platform) => {
      platform(this.tracking);
    });
  }

  trackEvent(name, trackingData) {
    const { isEnabled, isTrackingDisabled } = this.siteContext;

    if (!isEnabled || isTrackingDisabled) {
      return;
    }

    const eventName = getV2EventName(name, this.tracking);

    if (eventName) {
      this.siteContext.eventName = eventName;

      EventQueue.publish(eventName, {
        siteContext: this.siteContext,
        trackingData: trackingData || {},
      });
    }
  }

  trackSale(transaction) {
    const { gdprProtected } = this.siteContext;
    if (gdprProtected) {
      return;
    }

    const eventName = transaction.isTvod
      ? this.tracking.EVENTS.EVENT_AUTHENTICATION_PURCHASE_COMPLETED
      : this.tracking.EVENTS.EVENT_AUTHENTICATION_CONVERSION;

    if (eventName) {
      this.siteContext.eventName = eventName;
      EventQueue.publish(eventName, {
        siteContext: this.siteContext,
        transaction,
      });
    }
  }
}
