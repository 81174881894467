import EventQueue from './EventQueue';

const Twitter = (tracking) => {
  const trackTwitterPixel = (twitterPixelCodes, productId, eventCallback) => {
    if (typeof window.twq !== 'function') {
      return;
    }

    twitterPixelCodes?.forEach((twitterPixelCode) => {
      if (
        (twitterPixelCode.product_id &&
          twitterPixelCode.product_id === productId) ||
        !twitterPixelCode.product_id
      ) {
        eventCallback(twitterPixelCode);
      }
    });
  };

  const trackRegistration = (options) => {
    const {
      currentSite: { twitter_pixel_codes: twitterPixelCodes },
      currentProductId,
    } = options.siteContext;

    trackTwitterPixel(twitterPixelCodes, currentProductId, () => {
      window.twq('track', 'CompleteRegistration', {
        num_items: '1',
      });
    });
  };

  const trackSale = (options) => {
    const {
      currentSite: { twitter_pixel_codes: twitterPixelCodes },
    } = options.siteContext;
    const transaction = options.transaction;

    trackTwitterPixel(twitterPixelCodes, transaction.product_id, () => {
      window.twq('track', 'Purchase', {
        value: transaction.amount.cents / 100.0,
        currency: transaction.amount.currency,
        num_items: '1',
        content_type: 'product',
        content_ids: transaction.product_id,
        content_name: transaction.product_sku,
        order_id: transaction.id,
      });
    });
  };

  const trackPageOpen = (options) => {
    const {
      currentSite: { twitter_pixel_codes: twitterPixelCodes },
      currentProductId,
    } = options.siteContext;

    trackTwitterPixel(twitterPixelCodes, currentProductId, () => {
      window.twq('track', 'InitiateCheckout');
    });
  };

  const trackPageView = (options) => {
    const {
      currentSite: { twitter_pixel_codes: twitterPixelCodes },
      currentProductId,
    } = options.siteContext;

    // see OTTDEV-2174
    if (options.siteContext.isVideoView) {
      return;
    }

    trackTwitterPixel(
      twitterPixelCodes,
      currentProductId,
      (twitterPixelCode) => {
        window.twq('init', twitterPixelCode.pixel_code);
        window.twq('track', 'PageView');
      },
    );
  };

  const subscribeEvents = () => {
    EventQueue.subscribe(
      tracking.EVENTS.EVENT_AUTHENTICATION_REGISTRATION,
      trackRegistration,
    );
    EventQueue.subscribe(
      tracking.EVENTS.EVENT_AUTHENTICATION_CONVERSION,
      trackSale,
    );
    EventQueue.subscribe(
      tracking.EVENTS.EVENT_AUTHENTICATION_PURCHASE_COMPLETED,
      trackSale,
    );
    // TODO: move site_checkout_page_open to Tracking_v2.rb
    EventQueue.subscribe('site_checkout_page_open', trackPageOpen);
    EventQueue.subscribe(tracking.EVENTS.EVENT_SITE_PAGE_VIEW, trackPageView);
  };

  subscribeEvents();
};

export default Twitter;
