import { internalHeaders, resolveRequest } from 'js/utils/fetch';

import {
  FETCH_FEATURE_FLAGS,
  FETCH_INITIAL_DATA_FAILURE,
  FETCH_USER_SITE_DATA,
  SET_API_V2_CONFIG,
  UPDATE_CURRENT_PURCHASE,
  UPDATE_PURCHASED_STATUS,
} from './actionTypes';

export const productsExist = (products) =>
  Object.keys(products.items).length > 0;
export const searchLoadedProducts = (products, id) => products.items[id];

export const fetchUserAndSiteData = () => {
  return (dispatch) => {
    return fetch('/customers/data', {
      method: 'GET',
      headers: internalHeaders,
      credentials: 'same-origin',
    })
      .then(resolveRequest)
      .then((res) => dispatch({ type: FETCH_USER_SITE_DATA, data: res }))
      .catch((err) => {
        dispatch({ type: FETCH_INITIAL_DATA_FAILURE, data: err });
        console.warn(err);
      });
  };
};

export const updateCurrentPurchase = (currentPurchaseType) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CURRENT_PURCHASE, currentPurchaseType });
  };
};

export const fetchProductPurchaseStatus = (productId) => {
  return async (dispatch) => {
    const res = await fetch(`/customers/purchase_status/${productId}`, {
      method: 'GET',
      headers: internalHeaders,
      credentials: 'include',
    });

    const response = await res.json();

    dispatch({
      type: UPDATE_PURCHASED_STATUS,
      hasPurchasedProduct: response.status,
      preexistingProcessingTransaction:
        response.preexisting_processing_transaction,
    });

    return response.status;
  };
};

export const fetchFeatureFlags = () => {
  return async (dispatch) => {
    const res = await fetch('/customers/feature_flags/', {
      method: 'GET',
      headers: internalHeaders,
      credentials: 'same-origin',
    });

    const response = await res.json();

    dispatch({
      type: FETCH_FEATURE_FLAGS,
      featureFlags: response,
    });
  };
};

export const loadApiV2Config = () => {
  return (dispatch) => {
    dispatch({
      type: SET_API_V2_CONFIG,
      apiV2Config: {
        api_v2_url: window.VHX.config.api_v2_url,
        api_v2_token: window.VHX.config.token,
      },
    });
  };
};
