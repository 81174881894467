import EventQueue from './EventQueue';

const Facebook = (tracking) => {
  const trackFbPixel = (fbPixelCodes, productId, eventCallback) => {
    if (typeof window.fbq !== 'function') {
      return;
    }

    fbPixelCodes?.forEach((fbPixelCode) => {
      if (
        (fbPixelCode.product_id && fbPixelCode.product_id === productId) ||
        !fbPixelCode.product_id
      ) {
        eventCallback(fbPixelCode);
      }
    });
  };

  const trackEvent = (options) => {
    const {
      currentSite: { facebook_pixel_codes: fbPixelCodes },
      currentProductId,
    } = options.siteContext;

    trackFbPixel(fbPixelCodes, currentProductId, (fbPixelCode) => {
      window.fbq('trackSingle', fbPixelCode.pixel_code, 'CompleteRegistration');
    });
  };

  const trackSale = (options) => {
    const {
      currentSite: { facebook_pixel_codes: fbPixelCodes },
    } = options.siteContext;
    const transaction = options.transaction;

    trackFbPixel(fbPixelCodes, transaction.product_id, (fbPixelCode) => {
      window.fbq('trackSingle', fbPixelCode.pixel_code, 'Purchase', {
        currency: transaction.amount.currency,
        value: transaction.amount.cents / 100.0,
        content_name: transaction.product_sku,
        num_items: 1,
        content_type: 'product',
        content_ids: transaction.product_id,
      });
    });
  };

  const trackPageOpen = (options) => {
    const {
      currentSite: { facebook_pixel_codes: fbPixelCodes },
      currentProductId,
    } = options.siteContext;

    trackFbPixel(fbPixelCodes, currentProductId, (fbPixelCode) => {
      window.fbq(
        'trackSingleCustom',
        fbPixelCode.pixel_code,
        'InitiateCheckout',
      );
    });
  };

  const trackPageView = (options) => {
    const {
      currentSite: { facebook_pixel_codes: fbPixelCodes },
    } = options.siteContext;

    if (
      !options.siteContext.isVideoView && // see OTTDEV-1778
      typeof window.fbq === 'function' &&
      fbPixelCodes &&
      Array.isArray(fbPixelCodes)
    ) {
      fbPixelCodes.forEach((fbPixelCode) => {
        window.fbq('init', fbPixelCode.pixel_code);
        window.fbq('track', 'PageView');
      });
    }
  };

  const subscribeEvents = () => {
    EventQueue.subscribe(
      tracking.EVENTS.EVENT_AUTHENTICATION_REGISTRATION,
      trackEvent,
    );
    EventQueue.subscribe(
      tracking.EVENTS.EVENT_AUTHENTICATION_CONVERSION,
      trackSale,
    );
    EventQueue.subscribe(
      tracking.EVENTS.EVENT_AUTHENTICATION_PURCHASE_COMPLETED,
      trackSale,
    );
    // TODO: move site_checkout_page_open to Tracking_v2.rb
    EventQueue.subscribe('site_checkout_page_open', trackPageOpen);
    EventQueue.subscribe(tracking.EVENTS.EVENT_SITE_PAGE_VIEW, trackPageView);
  };

  subscribeEvents();
};

export default Facebook;
