class EventQueue {
  constructor() {
    this.events = {};
  }

  subscribe(event, listener) {
    // make sure there is a event and listener
    if (!event || !listener) {
      return;
    }

    // create the event if not yet created
    if (!this.events[event]) {
      this.events[event] = [];
    }

    // add the listener to queue
    this.events[event].push(listener);
  }

  publish(event, data) {
    // make sure the event and listeners exist
    if (!this.events[event] || this.events[event].length < 1) {
      return;
    }

    // send the event to all listeners
    this.events[event].forEach((listener) => {
      listener(data || {});
    });
  }

  unsubscribeAll() {
    this.events = {};
  }
}

const queue = new EventQueue();

export default queue;
