import EventQueue from './EventQueue';
import { getEventProperties, getPlatformEventName } from './utils';

const Platform = (tracking) => {
  const trackEvent = (options) => {
    const { siteContext, trackingData = {} } = options;
    const platformEventName = getPlatformEventName(
      siteContext.eventName,
      tracking,
    );

    const additionalProps = {};
    for (const [key, value] of Object.entries(trackingData)) {
      if (value !== undefined && value !== null) {
        additionalProps[key] = value;
      }
    }

    const properties = getEventProperties({
      tracking,
      siteContext,
      name: platformEventName,
      additionalProps: additionalProps,
    });

    const props = Object.entries(properties)
      .map(([key, property]) => {
        return key + '=' + encodeURIComponent(property);
      })
      .join('&');

    if (window._vhx?.environment === 'production') {
      new Image().src =
        tracking.PROPERTIES.PROPERTY_COLLECTOR_URL + '?' + props;
    } else {
      // For local testing

      console.log(tracking.PROPERTIES.PROPERTY_COLLECTOR_URL + '?' + props);
    }
  };

  const subscribeEvents = () => {
    const cloneEvents = { ...tracking.EVENTS };
    // Platform event does not need identification
    delete cloneEvents[tracking.EVENTS.EVENT_AUTHENTICATION_IDENTIFY_USER];

    Object.entries(cloneEvents).forEach((event) => {
      EventQueue.subscribe(event[1], trackEvent);
    });
  };

  subscribeEvents();
};

export default Platform;
