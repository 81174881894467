import { combineReducers } from 'redux';

import { UPDATE_CUSTOMER } from '~/apps/Customer/pages/checkout/actions';

import {
  FETCH_FEATURE_FLAGS,
  FETCH_INITIAL_DATA_FAILURE,
  FETCH_USER_SITE_DATA,
  SET_API_V2_CONFIG,
  UPDATE_CURRENT_PURCHASE,
  UPDATE_PURCHASED_STATUS,
} from './actionTypes';

const currentInitialState = {
  customer: Object.assign({}, window.VHX.data.customer),
  site: Object.assign({}, window.VHX.data.site),
  hasPurchasedProduct: !!window.VHX.data.hasPurchasedProduct,
  preexistingProcessingTransaction: Object.assign(
    {},
    window.VHX.data.preexistingProcessingTransaction,
  ),
  currentPurchaseType: '',
  adminPreview: window.VHX.data.adminPreview,
  featureFlags: {},
  checkoutCountryOverride: window.VHX.data.checkoutCountryOverride,
  geo: window._vhx.geo,
};

export const current = (state = currentInitialState, action) => {
  switch (action.type) {
    case FETCH_USER_SITE_DATA:
      return Object.assign({}, state, action.data);

    case FETCH_INITIAL_DATA_FAILURE:
      return Object.assign({}, state, action.data);

    case UPDATE_CUSTOMER:
      return Object.assign({}, state, {
        customer: action.data,
      });

    case FETCH_FEATURE_FLAGS:
      return Object.assign({}, state, {
        featureFlags: action.featureFlags,
      });

    case SET_API_V2_CONFIG:
      return Object.assign({}, state, {
        apiV2Config: action.apiV2Config,
      });

    case UPDATE_PURCHASED_STATUS:
      return {
        ...state,
        hasPurchasedProduct: action.hasPurchasedProduct,
        preexistingProcessingTransaction:
          action.preexistingProcessingTransaction,
      };

    case UPDATE_CURRENT_PURCHASE:
      return { ...state, currentPurchaseType: action.currentPurchaseType };

    default:
      return state;
  }
};

const GlobalSiteReducer = combineReducers({
  current,
});

export default GlobalSiteReducer;
