import EventQueue from './EventQueue';

const Google = (tracking) => {
  const trackAdWords = (options) => {
    const {
      currentSite: { adwords_pixel_codes: adwordsPixelCodes },
    } = options.siteContext;
    const transaction = options.transaction;

    adwordsPixelCodes?.forEach((adword) => {
      if (adword.product_id === transaction.product_id || !adword.product_id) {
        new Image().src =
          'https://www.googleadservices.com/pagead/conversion/' +
          adword.pixel_id +
          '/?value=' +
          transaction.amount.cents / 100.0 +
          '&currency_code=USD&label=' +
          adword.pixel_label +
          '&amp;guid=ON&amp;script=0';
      }
    });
  };

  const trackSale = (options) => {
    trackAdWords(options);
  };

  const subscribeEvents = () => {
    // gtag is only used by GA4, but we should check for it's presence here
    // in order to avoid tracking adwords for sites with tracking disabled
    if (!window.gtag) {
      return;
    }
    Object.entries(tracking.EVENTS).forEach((event) => {
      if (event[1] === tracking.EVENTS.EVENT_AUTHENTICATION_CONVERSION) {
        EventQueue.subscribe(
          tracking.EVENTS.EVENT_AUTHENTICATION_CONVERSION,
          trackSale,
        );
      } else if (
        event[1] === tracking.EVENTS.EVENT_AUTHENTICATION_PURCHASE_COMPLETED
      ) {
        EventQueue.subscribe(
          tracking.EVENTS.EVENT_AUTHENTICATION_PURCHASE_COMPLETED,
          trackSale,
        );
      }
    });
  };

  subscribeEvents();
};

export default Google;
