import EventQueue from './EventQueue';
import {
  getEventProperties,
  getPageViewName,
  getVideoProperties,
} from './utils';
import VHXplayer from './VHXPlayer';

const Segment = (tracking) => {
  let _options = null;
  let player;

  const playerEventMap = {
    ended: tracking.EVENTS.EVENT_SEGMENT_VIDEO_ENDED,
    pause: tracking.EVENTS.EVENT_SEGMENT_VIDEO_PAUSE,
    play: tracking.EVENTS.EVENT_SEGMENT_VIDEO_PLAY,
    seeked: tracking.EVENTS.EVENT_SEGMENT_VIDEO_SEEKED,
    seeking: tracking.EVENTS.EVENT_SEGMENT_VIDEO_SEEKING,
    timeupdate: tracking.EVENTS.EVENT_SEGMENT_VIDEO_TIMEUPDATE,
    resume: tracking.EVENTS.EVENT_SEGMENT_VIDEO_RESUME,
    startbuffering: tracking.EVENTS.EVENT_SEGMENT_VIDEO_START_BUFFER,
    endbuffering: tracking.EVENTS.EVENT_SEGMENT_VIDEO_END_BUFFER,
    adstart: tracking.EVENTS.EVENT_SEGMENT_VIDEO_AD_START,
    adclicked: tracking.EVENTS.EVENT_SEGMENT_VIDEO_AD_CLICKED,
    adfinish: tracking.EVENTS.EVENT_SEGMENT_VIDEO_AD_FINISH,
    adfail: tracking.EVENTS.EVENT_SEGMENT_VIDEO_AD_FAIL,
    adskip: tracking.EVENTS.EVENT_SEGMENT_VIDEO_AD_SKIP,
  };

  const eventMap = {
    // sign in viewed
    [tracking.EVENTS.EVENT_AUTHENTICATION_LOGIN]:
      tracking.EVENTS.EVENT_SEGMENT_AUTHENTICATION_SIGNIN_VIEW,
    // sign in viewed
    [tracking.EVENTS.EVENT_AUTHENTICATION_SIGNIN_VIEW]:
      tracking.EVENTS.EVENT_SEGMENT_AUTHENTICATION_SIGNIN_VIEW,
    // sign up viewed
    [tracking.EVENTS.EVENT_AUTHENTICATION_SIGNUP_VIEW]:
      tracking.EVENTS.EVENT_SEGMENT_AUTHENTICATION_SIGNUP_VIEW,
    // sign up started
    [tracking.EVENTS.EVENT_AUTHENTICATION_SIGNUP_STARTED]:
      tracking.EVENTS.EVENT_SEGMENT_AUTHENTICATION_SIGNUP_STARTED,
    // Authentication purchase
    [tracking.EVENTS.EVENT_AUTHENTICATION_PURCHASE_STARTED]:
      tracking.EVENTS.EVENT_SEGMENT_AUTHENTICATION_PURCHASE_STARTED,
    // Authentication conversion
    [tracking.EVENTS.EVENT_AUTHENTICATION_CONVERSION]:
      tracking.EVENTS.EVENT_SEGMENT_AUTHENTICATION_CONVERSION,
    // Purchase complete
    [tracking.EVENTS.EVENT_AUTHENTICATION_PURCHASE_COMPLETED]:
      tracking.EVENTS.EVENT_SEGMENT_AUTHENTICATION_CONVERSION,
    // Authentication registration
    [tracking.EVENTS.EVENT_AUTHENTICATION_REGISTRATION]:
      tracking.EVENTS.EVENT_SEGMENT_AUTHENTICATION_REGISTRATION,
    // Signin complete
    [tracking.EVENTS.EVENT_AUTHENTICATION_SIGNIN_COMPLETE]:
      tracking.EVENTS.EVENT_SEGMENT_AUTHENTICATION_SIGNIN_COMPLETE,
    // Extra viewed
    [tracking.EVENTS.EVENT_EXTRA_VIEWED]:
      tracking.EVENTS.EVENT_SEGMENT_EXTRA_VIEWED,
    // Add to watchlist
    [tracking.EVENTS.EVENT_SITE_WATCHLIST_ADD]:
      tracking.EVENTS.EVENT_SEGMENT_VIDEO_WATCHLIST_ADD,
    // Removed from watchlist
    [tracking.EVENTS.EVENT_SITE_WATCHLIST_REMOVE]:
      tracking.EVENTS.EVENT_SEGMENT_VIDEO_WATCHLIST_REMOVE,
    // Video comment added
    [tracking.EVENTS.EVENT_VIDEO_COMMENT]:
      tracking.EVENTS.EVENT_SEGMENT_VIDEO_COMMENT_ADDED,
    // Search item selected
    [tracking.EVENTS.EVENT_SEARCH_ITEM_SELECTED]:
      tracking.EVENTS.EVENT_SEGMENT_SEARCH_ITEM_SELECTED,
    // Search executed
    [tracking.EVENTS.EVENT_SEARCH]:
      tracking.EVENTS.EVENT_SEGMENT_SEARCH_EXECUTED,
  };

  const getCanonicalTitle = (canonicalParent) => {
    if (canonicalParent) {
      if (canonicalParent?.parent?.type === 'series') {
        return `${canonicalParent.parent.name}: ${canonicalParent.name}`;
      } else {
        return canonicalParent.name;
      }
    }
    return '';
  };

  const segmentProperties = () => {
    const { eventName, collectionTitle, videoTitle, canonicalCollection } =
      _options.siteContext;
    const eventProperties = getEventProperties({
      tracking,
      siteContext: _options.siteContext,
      name: getSegmentEventName(eventName),
      additionalProps: {
        type: _options.trackingData?.type,
        url: _options.trackingData?.url,
      },
    });
    const { collection_id, video_id } = eventProperties;

    const canonicalTitle = getCanonicalTitle(canonicalCollection);

    const collection = canonicalCollection
      ? {
          collection_id: canonicalCollection.id,
          collection_title: canonicalTitle,
        }
      : { collection_id, collection_title: collectionTitle };

    return {
      ...eventProperties,
      ...collection,
      video_title: video_id ? videoTitle : null,
    };
  };

  const segmentPageProperties = () => {
    return { ...segmentProperties(), path: window.location.pathname };
  };

  const segmentTraits = () => {
    const { currentUser, currentSite } = _options.siteContext;

    return {
      name: currentUser.name,
      email: currentUser.email,
      vimeo_id: currentUser.id ? String(currentUser.id) : undefined,
      sso_id:
        currentSite.sso_enabled && currentUser.external_user_id
          ? String(currentUser.external_user_id)
          : undefined,
    };
  };

  const segmentContext = () => {
    const { currentSite, currentUser } = _options.siteContext;
    const coreProperties = getEventProperties({
      tracking,
      siteContext: _options.siteContext,
    });
    const externalIds = [
      {
        collection: 'users',
        encoding: 'none',
        type: 'vimeo_id',
        id: currentUser.id ? String(currentUser.id) : undefined,
      },
    ];

    if (currentSite.sso_enabled) {
      externalIds.push({
        collection: 'users',
        encoding: 'none',
        type: 'sso_id',
        id: currentUser.external_user_id
          ? String(currentUser.external_user_id)
          : undefined,
      });
    }

    return {
      context: {
        app: {
          name:
            currentSite.title ||
            coreProperties[tracking.PROPERTIES.PROPERTY_PLATFORM],
          version:
            coreProperties[tracking.PROPERTIES.PROPERTY_PLATFORM_VERSION],
        },
        externalIds: externalIds,
        locale: navigator.language,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      traits: segmentTraits(),
    };
  };

  const segmentTransactionContext = (transaction = {}) => {
    const { product_id, product_name, product_sku, campaign } = transaction;

    return {
      revenue: transaction.amount.cents / 100.0,
      context: {
        transaction: {
          product_id,
          product_name,
          product_sku,
          campaign,
        },
      },
    };
  };

  const getSegmentEventName = (name) => {
    return eventMap[name];
  };

  const segmentHeartbeat = (currentTimeRounded) => {
    const { currentSite, setVideoProperty, video } = _options.siteContext;
    const timeUpdateFrequency = currentSite.segment_heartbeat_frequency;

    if (
      currentTimeRounded % timeUpdateFrequency === 0 &&
      timeUpdateFrequency > 0
    ) {
      if (video.last_ten_seconds_tracked !== currentTimeRounded) {
        setVideoProperty('last_ten_seconds_tracked', currentTimeRounded);
        trackVideoEvent(tracking.EVENTS.EVENT_SEGMENT_VIDEO_TIMEUPDATE);
      }
    }
  };

  const onPlay = ({ currentTime }) => {
    const { setVideoProperty, video } = _options.siteContext;
    setVideoProperty('current_time', currentTime);

    if (!video.has_firstplay) {
      trackVideoEvent(tracking.EVENTS.EVENT_SEGMENT_VIDEO_PLAY);
      setVideoProperty('has_firstplay', true);
    }
  };

  const trackVideoProgress = (currentTimeRounded, durationRounded) => {
    const { setVideoProperty, video } = _options.siteContext;
    const quartileOneThreshold = Math.floor(durationRounded * 0.25);
    const quartileTwoThreshold = Math.floor(durationRounded * 0.5);
    const quartileThreeThreshold = Math.floor(durationRounded * 0.75);
    const contentCompleteThreshold = Math.floor(durationRounded * 0.9);

    if (
      currentTimeRounded >= quartileOneThreshold &&
      !video.has_watched_quartile_one
    ) {
      trackVideoEvent(
        tracking.EVENTS.EVENT_SEGMENT_VIDEO_QUARTER_PROGRESS_PERCENTILE,
      );
      setVideoProperty('has_watched_quartile_one', 1);
    }
    if (
      currentTimeRounded >= quartileTwoThreshold &&
      !video.has_watched_quartile_two
    ) {
      trackVideoEvent(
        tracking.EVENTS.EVENT_SEGMENT_VIDEO_HALF_PROGRESS_PERCENTILE,
      );
      setVideoProperty('has_watched_quartile_two', 1);
    }
    if (
      currentTimeRounded >= quartileThreeThreshold &&
      !video.has_watched_quartile_three
    ) {
      trackVideoEvent(
        tracking.EVENTS.EVENT_SEGMENT_VIDEO_THIRD_PROGRESS_PERCENTILE,
      );
      setVideoProperty('has_watched_quartile_three', 1);
    }
    if (
      currentTimeRounded >= contentCompleteThreshold &&
      !video.has_watched_quartile_complete
    ) {
      trackVideoEvent(
        tracking.EVENTS.EVENT_SEGMENT_VIDEO_COMPLETE_PROGRESS_PERCENTILE,
      );
      setVideoProperty('has_watched_quartile_complete', 1);
    }
  };

  const onTimeupdate = ({ currentTime, duration }) => {
    const currentTimeRounded = Math.floor(currentTime);
    const durationRounded = Math.ceil(duration);
    const { setVideoProperty, video } = _options.siteContext;
    // if we have a duration, we have received metadata
    // can check the `currentTimeRounded` time and ensure we don't overtrack quartiles
    if (video.duration !== false && video.segment_has_initialized === false) {
      trackVideoProgress(currentTimeRounded, durationRounded);
      setVideoProperty('segment_has_initialized', 1);
    } else {
      setVideoProperty('duration', durationRounded);
    }

    segmentHeartbeat(currentTimeRounded);
    // track quartiles
    // timeupdate is sent frequently, make sure to check for range, not exact matches
    if (video.last_duration_change !== currentTimeRounded) {
      trackVideoProgress(currentTimeRounded, durationRounded);
      setVideoProperty('last_duration_change', currentTimeRounded);
      setVideoProperty('current_time', currentTime);
    }
  };

  const trackVideoEvent = (name) => {
    if (window.analytics) {
      const { collectionTitle, videoTitle } = _options.siteContext;

      const videoEventProperty = {
        ...getVideoProperties(_options, tracking, name),
        collection_title: collectionTitle,
        video_title: videoTitle,
      };

      window.analytics.track(name, videoEventProperty, segmentContext());
    }
  };

  const triggerPlayerEvent = ({ playerEvent, currentTime, duration }) => {
    if (!_options) return;

    const { setVideoProperty } = _options.siteContext;
    const currentTimeRounded = Math.floor(currentTime);
    const durationRounded = Math.ceil(duration);

    // Setting current_time and duration to video always
    setVideoProperty('current_time', currentTimeRounded);
    setVideoProperty('duration', durationRounded);

    if (playerEvent === 'timeupdate') {
      onTimeupdate({ playerEvent, currentTime, duration });
    } else if (playerEvent === 'play') {
      onPlay({ playerEvent, currentTime, duration });
    } else {
      trackVideoEvent(playerEventMap[playerEvent]);
    }
  };

  // Not tracking fullscreen change
  // sets is_fullscreen value
  const onFullscreenChange = () => {
    const { video, setVideoProperty } = _options.siteContext;
    setVideoProperty('is_fullscreen', !video.is_fullscreen);
  };

  const loadPlayer = (options) => {
    _options = options;
    player = VHXplayer();

    // Set video values
    if (window.VHX.video) {
      const { setVideoProperty } = _options.siteContext;
      const playerInstance = player?.getInstance();
      const isDRM = window.VHX.video.isDRM === 'false' ? 0 : 1;
      const isTrailer = window.VHX.video.isTrailer === 'false' ? 0 : 1;

      setVideoProperty('current_src', playerInstance?.currentSrc());
      setVideoProperty('is_chromecast', playerInstance?.isCasting());
      setVideoProperty('is_drm', isDRM);
      setVideoProperty('is_fullscreen', playerInstance?.isFullscreen());
      setVideoProperty('is_trailer', isTrailer);
    }
  };

  const trackEvent = (options) => {
    const { eventName } = options.siteContext;

    if (window.analytics && eventName) {
      // setting data to local state to avoid passing this to every method
      _options = options;

      window.analytics.ready(() => {
        const segmentName = getSegmentEventName(eventName);
        if (segmentName) {
          window.analytics.track(
            segmentName,
            segmentProperties(),
            segmentContext(),
          );
        }
      });
    }
  };

  const trackSale = (options) => {
    const { eventName } = options.siteContext;
    _options = options;
    const _segmentContext = segmentContext();
    const _segmentTransactionContext = segmentTransactionContext(
      options.transaction,
    );

    if (window.analytics && eventName) {
      const segmentName = getSegmentEventName(eventName);
      if (segmentName) {
        const segmentSaleProperties = {
          ...segmentProperties(),
          order_id: options.transaction.id,
          transaction_id: options.transaction.id,
        };
        window.analytics.track(segmentName, segmentSaleProperties, {
          ..._segmentContext,
          context: {
            ..._segmentContext.context,
            ..._segmentTransactionContext.context,
          },
          traits: segmentTraits(),
          revenue: _segmentTransactionContext.revenue,
        });
      }
    }
  };

  const toggleUserId = () => {
    const { currentUser, currentSite } = _options.siteContext;

    if (currentSite.segment_includes_user_id && currentUser.id) {
      if (window.analytics.user().id() !== String(currentUser.id)) {
        window.analytics.user().id(currentUser.id);
      }
    } else {
      window.analytics.user().id(null);
    }
  };

  const identifyUser = () => {
    const { currentSite, currentUser } = _options.siteContext;

    if (currentUser.id) {
      const _segmentContext = { ...segmentContext(), traits: undefined };
      currentSite.segment_includes_user_id
        ? window.analytics.identify(
            currentUser.id,
            segmentTraits(),
            _segmentContext,
          )
        : window.analytics.identify(segmentTraits(), segmentContext());
    } else {
      window.analytics.reset();
    }
  };

  const trackIdentification = (options) => {
    const { eventName } = options.siteContext;
    _options = options;

    if (window.analytics && eventName) {
      identifyUser();
    }
  };

  const trackPageView = (options) => {
    _options = options;

    if (window.analytics) {
      window.analytics.ready(() => {
        toggleUserId();

        window.analytics.page(
          getPageViewName(_options.siteContext),
          segmentPageProperties(),
          segmentContext(),
        );
      });
    }
  };

  const trackSearch = (options) => {
    const { eventName } = options.siteContext;
    if (window.analytics && eventName) {
      _options = options;
      const segmentName = getSegmentEventName(eventName);
      const searchParams = new URLSearchParams(window.location.search);
      const searchProperties = {
        ...segmentProperties(),
        search_query: searchParams.get('q'),
      };

      if (segmentName) {
        window.analytics.track(segmentName, searchProperties, segmentContext());
      }
    }
  };

  const subscribeEvents = () => {
    if (!window.Segment_ID) {
      return;
    }

    const IDENTIFICATION_EVENTS = [
      tracking.EVENTS.EVENT_AUTHENTICATION_IDENTIFY_USER,
      tracking.EVENTS.EVENT_AUTHENTICATION_SIGNIN_COMPLETE,
      tracking.EVENTS.EVENT_SETTINGS_SAVE_PROFILE,
      tracking.EVENTS.EVENT_SETTINGS_SAVE_CARD,
      tracking.EVENTS.EVENT_SETTINGS_SAVE_NOTIFICATIONS,
    ];

    /**
     * Subscribing to other segment events
     */
    Object.entries(tracking.EVENTS).forEach((event) => {
      const name = event[1];
      /**
       * Tracking for transaction
       */
      if (name === tracking.EVENTS.EVENT_AUTHENTICATION_CONVERSION) {
        EventQueue.subscribe(name, trackSale);
      } else if (
        name === tracking.EVENTS.EVENT_AUTHENTICATION_PURCHASE_COMPLETED
      ) {
        EventQueue.subscribe(
          tracking.EVENTS.EVENT_AUTHENTICATION_PURCHASE_COMPLETED,
          trackSale,
        );
      } else if (IDENTIFICATION_EVENTS.includes(name)) {
        /**
         * Tracking for login, registration and profile update
         */
        EventQueue.subscribe(name, trackIdentification);
      } else if (name === tracking.EVENTS.EVENT_SITE_PAGE_VIEW) {
        EventQueue.subscribe(name, trackPageView);
      } else if (name === tracking.EVENTS.EVENT_SITE_VIDEO_CLICK) {
        /**
         * Subscribing to video view event
         */
        EventQueue.subscribe(name, loadPlayer);
      } else if (name === tracking.EVENTS.EVENT_SEARCH) {
        EventQueue.subscribe(name, trackSearch);
      } else {
        EventQueue.subscribe(name, trackEvent);
      }
    });
    // Track segment for sign in complete
    EventQueue.subscribe(
      tracking.EVENTS.EVENT_AUTHENTICATION_SIGNIN_COMPLETE,
      trackEvent,
    );
    /**
     * Subscribing to player events here
     */
    Object.keys(playerEventMap).forEach((playerEvent) => {
      EventQueue.subscribe(playerEvent, triggerPlayerEvent);
    });

    EventQueue.subscribe('fullscreenchange', onFullscreenChange);
  };

  subscribeEvents();
};

export default Segment;
