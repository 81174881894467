export const fireEvent = (
  name: string,
  parameters?: Record<string, unknown>,
) => {
  if (window.Tracking.GOOGLE_ANALYTICS_4.HAS_OTT_GTAG) {
    fireOTTEvent(name, parameters);
  }
  if (window.Tracking.GOOGLE_ANALYTICS_4.HAS_SITE_GTAG) {
    fireSiteEvent(name, parameters);
  }
};

const fireOTTEvent = (name: string, parameters?: Record<string, unknown>) => {
  window.gtag('event', name, {
    ...parameters,
    send_to: window.Tracking.GOOGLE_ANALYTICS_4.OTT_WEB_ID,
  });
};

const fireSiteEvent = (name: string, parameters?: Record<string, unknown>) => {
  window.gtag('event', name, {
    ...parameters,
    send_to: window.Tracking.GOOGLE_ANALYTICS_4.SITE_WEB_ID,
  });
};

export const OTT_WEB_METHOD = 'OTT Web';
