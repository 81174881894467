import { fireEvent } from 'js/utils/google-analytics-4-helpers';
import { Tracking } from '~/types';

import EventQueue from './EventQueue';

type Options = {
  siteContext: {
    eventName: string;
  };
  trackingData?: Record<string, unknown>;
};

const GoogleAnalytics4 = (tracking: Tracking) => {
  const trackEvent = ({ siteContext, trackingData }: Options) => {
    fireEvent(siteContext.eventName, trackingData);
  };

  const subscribeEvents = () => {
    if (!window.gtag) {
      return;
    }

    Object.values(tracking.GOOGLE_ANALYTICS_4.EVENTS).forEach((eventName) => {
      EventQueue.subscribe(eventName, trackEvent);
    });
  };

  subscribeEvents();
};

export default GoogleAnalytics4;
