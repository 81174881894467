import EventQueue from './EventQueue';

const AD_CLICKED = 'adclicked';

export const PLAYER_EVENTS = [
  'ended',
  'pause',
  'play',
  'seeked',
  'seeking',
  'timeupdate',
  'resume',
  'startbuffering',
  'endbuffering',
  'adstart',
  'adfinish',
  'adfail',
  'adskip',
  'fullscreenchange',
];

const VHXPlayer = () => {
  let _waitForVHX = null;
  let player = null;

  const handlePlayback = (event, currentTime, duration, eventName) => {
    const _currentTime = currentTime || player.currentTime() || 0;
    const _duration = duration || player.duration() || 0;

    EventQueue.publish(eventName, {
      event,
      currentTime: _currentTime,
      duration: _duration,
      playerEvent: eventName,
    });
  };

  const bindEvents = () => {
    PLAYER_EVENTS.forEach((eventName) => {
      player?.on(eventName, (event, currentTime, duration) => {
        handlePlayback(event, currentTime, duration, eventName);
      });
    });

    // listening to adclicked event from player embed
    window.addEventListener(
      'message',
      (event) => {
        const data =
          event.data && typeof event.data === 'string'
            ? JSON.parse(event.data)
            : {};
        if (data.method === AD_CLICKED) {
          handlePlayback({}, undefined, undefined, AD_CLICKED);
        }
      },
      false,
    );
  };

  const waitForVHX = () => {
    _waitForVHX = setInterval(() => {
      checkForVHX();
    }, 1000);
  };

  const checkForVHX = () => {
    if (window.VHX && typeof window.VHX.Player === 'function') {
      clearInterval(_waitForVHX);
      setupPlayer();
    }
  };

  const setupPlayer = () => {
    player = window.VHX.Player('watch-embed');
    bindEvents();
  };

  const init = () => {
    if (!window.VHX.Player) {
      waitForVHX();
    } else {
      setupPlayer();
    }
  };

  init();

  return {
    getInstance: () => player,
  };
};

export default VHXPlayer;
