import isEmpty from 'lodash/isEmpty';
import { combineReducers } from 'redux';

import { SIGNUP } from '~/apps/Customer/pages/checkout/constants';

import {
  CLEAR_FREE_TRIAL,
  ERROR_OCCURRED,
  ERROR_RESET,
  ERROR_RESET_ALL,
  SELECT_PURCHASE_OPTION,
  SET_GIFT_PARAMS,
  SET_LOGIN_INFO,
  SET_PRICES,
  SET_PRODUCT,
  SET_PURCHASE_TYPE_SELECTED,
  SET_REGISTRATION_TYPE,
  SET_SIGNUP_INFO,
  SET_SIGNUP_INFO_VALIDITY,
  UPDATE_PROCESSING_PURCHASE,
} from './actions';

const defaultPurchaseOption = {
  auth_formatted: null,
  auth_currency: 'USD',
  country_code: null,
  discount_formatted: null,
  discount_percent: null,
  error_message: '',
  frequency: 'yearly',
  gift_formatted: null,
  gift_label: null,
  giftParams: {
    message: '',
    recipientEmail: '',
    sendAt: null,
    senderEmail: '',
    valid: false,
  },
  inclusive_taxes: false,
  is_zero_decimal: false,
  postal_code: null,
  pwywPrice: '',
  renewal_date: '',
  subtotal_formatted: null,
  conversion_event_discount: null,
  conversion_event_tax: null,
  conversion_event_value: null,
  subtotal: 0,
  symbol: '$',
  tax_formatted: null,
  tax: 0,
  total_formatted: null,
  total: 0,
  type: 'subscription',
  zero_formatted: null,
};

export const purchaseOption = (state = defaultPurchaseOption, action) => {
  switch (action.type) {
    case SELECT_PURCHASE_OPTION:
      return Object.assign({}, state, action.option);

    case SET_GIFT_PARAMS:
      return {
        ...state,
        giftParams: {
          ...state.giftParams,
          ...action.giftParams,
        },
      };
    default:
      return state;
  }
};

const defaultPrices = {
  prices: {
    yearly: {},
    monthly: {},
    gift_prices: [],
  },
};

const paidSubscription = window.VHX.data.paidSubscription || {};
const defaultProduct = Object.assign(
  {},
  window.VHX.data.product,
  defaultPrices,
);

const paidSubscriptionProduct = (state = paidSubscription, _action) => {
  return state;
};

const hasPaidSubscriptionProduct = (
  state = !isEmpty(paidSubscription),
  _action,
) => {
  return state;
};

export const product = (state = defaultProduct, action) => {
  switch (action.type) {
    case SET_PRODUCT:
      return Object.assign({}, state, action.product);
    case SET_PRICES:
      return {
        ...state,
        prices: {
          ...action.prices,
          gift_prices: action.prices.gift_prices,
        },
      };

    case CLEAR_FREE_TRIAL:
      return {
        ...state,
        has_free_trial: false,
        force_charge: true,
      };

    default:
      return state;
  }
};

const initialErrorState = {
  payment: null,
};

export const error = (state = initialErrorState, action) => {
  const { type, payload: { from, message, id, notificationType } = {} } =
    action;
  switch (type) {
    case ERROR_OCCURRED:
      return {
        ...state,
        [from]: { message, id, notificationType },
      };

    case ERROR_RESET:
      return {
        ...state,
        [from]: null,
      };

    case ERROR_RESET_ALL:
      return {
        ...initialErrorState,
      };

    default:
      return state;
  }
};

export const isProcessingPurchase = (state = false, action) => {
  switch (action.type) {
    case UPDATE_PROCESSING_PURCHASE:
      return action.isProcessing;
    default:
      return state;
  }
};

export const purchaseTypeSelected = (state = false, action) => {
  switch (action.type) {
    case SET_PURCHASE_TYPE_SELECTED:
      return action.purchaseTypeSelected;
    default:
      return state;
  }
};

const initialRegistrionInfo = {
  registrationType: SIGNUP,
  signupInfo: {
    name: '',
    email: '',
    password: '',
    newsLetterAgree: !!window.VHX.data.marketingOptinDefault,
    valid: false,
  },
  loginInfo: {
    email: '',
    password: '',
    valid: false,
  },
};

export const registrationInfo = (state = initialRegistrionInfo, action) => {
  switch (action.type) {
    case SET_REGISTRATION_TYPE:
      return {
        ...state,
        registrationType: action.registrationType,
      };

    case SET_SIGNUP_INFO:
      return {
        ...state,
        signupInfo: action.signupInfo,
      };

    case SET_SIGNUP_INFO_VALIDITY:
      return {
        ...state,
        signupInfo: { ...state.signupInfo, valid: action.valid },
      };

    case SET_LOGIN_INFO:
      return {
        ...state,
        loginInfo: action.loginInfo,
      };

    default:
      return state;
  }
};

const CheckoutReducer = combineReducers({
  purchaseOption,
  product,
  hasPaidSubscriptionProduct,
  paidSubscriptionProduct,
  error,
  isProcessingPurchase,
  purchaseTypeSelected,
  registrationInfo,
});

export default CheckoutReducer;
