import Cookies from 'js-cookie';

import { OTT_WEB_METHOD } from 'js/utils/google-analytics-4-helpers';

import StatsAdapter from './StatsAdapter';

/**
 * Tracking, Page, _current_user, _current_site are set by server
 * to the window object
 */
const props = {
  tracking: window.Tracking || {},
  page: window.Page || {},
  currentUser: window._current_user || {},
  currentSite: window._current_site || {},
};

const StatsV2 = () => {
  const trackMyListEvent = (contentAction, contentType, contentID) => {
    contentType === 'video'
      ? window.Stats.siteContext.setPageProperty('VIDEO_ID', contentID)
      : window.Stats.siteContext.setPageProperty('COLLECTION_ID', contentID);

    if (contentAction === 'my-list-added') {
      window.Stats.siteContext.setPageProperty('NAME', 'addwatchlist');
      window.Stats.trackEvent(props.tracking.EVENTS.EVENT_SITE_WATCHLIST_ADD);
    } else if (contentAction === 'my-list-removed') {
      window.Stats.siteContext.setPageProperty('NAME', 'removewatchlist');
      window.Stats.trackEvent(
        props.tracking.EVENTS.EVENT_SITE_WATCHLIST_REMOVE,
      );
    }
  };

  const bindEvents = () => {
    /**
     * jquery event handler "this" will not work as expected if Arrow function is used
     */
    function onClickAnchorTag() {
      const trackEventProps = $(this).data('track-event-properties');
      const url = $(this).attr('href');
      window.Stats.siteContext.eventProps = { ...trackEventProps, url };
      window.Stats.trackEvent($(this).data('track-event'), {
        ...trackEventProps,
        url,
      });
    }

    /**
     * jquery event handler "this" will not work as expected if Arrow function is used
     */
    function onSubmitForm() {
      const trackEventProps = $(this).data('track-event-properties');
      window.Stats.siteContext.eventProps = trackEventProps;
      window.Stats.trackEvent($(this).data('track-event'), trackEventProps);
    }

    $(document).on('click', 'a[data-track-event]', onClickAnchorTag);

    $('form[data-track-event]').on('submit', onSubmitForm);

    // listen for triggers
    $(window).on('tracking:propertyChange', (ev, arg) => {
      window.Stats.siteContext.setPageProperty('NAME', arg);
    });

    document.addEventListener('my-list-added', (e) => {
      if (e.detail?.type && e.detail?.id) {
        trackMyListEvent('my-list-added', e.detail.type, parseInt(e.detail.id));
      }
    });

    document.addEventListener('my-list-removed', (e) => {
      if (e.detail?.type && e.detail?.id) {
        trackMyListEvent(
          'my-list-removed',
          e.detail.type,
          parseInt(e.detail.id),
        );
      }
    });
    // listens to addwatchlist or removewatchlist
    window.addEventListener(
      'message',
      (event) => {
        let data = {};

        if (event.data && typeof event.data === 'string') {
          try {
            data = JSON.parse(event.data);
          } catch {
            console.warn('Unexpected message', event);
          }
        }

        if (data.method === 'watchlistadded') {
          trackMyListEvent(
            'my-list-added',
            'video',
            window.Stats.siteContext.videoID,
          );
        }

        if (data.method === 'watchlistremoved') {
          trackMyListEvent(
            'my-list-removed',
            'video',
            window.Stats.siteContext.videoID,
          );
        }
      },
      false,
    );

    $('body').on('search:complete', () => {
      window.Stats.trackEvent(props.tracking.EVENTS.EVENT_SEARCH);
    });

    $('body').on('switch:triggered', () => {
      window.Stats.trackEvent(props.tracking.EVENTS.EVENT_SITE_PAGE_VIEW);
    });
  };

  const initTracking = () => {
    // Trigger page view
    window.Stats.trackEvent(props.tracking.EVENTS.EVENT_SITE_PAGE_VIEW);

    if (window.location.pathname.includes('/checkout')) {
      window.Stats.trackEvent('site_checkout_page_open');
    }

    // Check if "logged_in" cookie is present
    if (Cookies.get('logged_in') === '1') {
      window.Stats.trackEvent(
        props.tracking.EVENTS.EVENT_AUTHENTICATION_SIGNIN_COMPLETE,
      );
      window.Stats.trackEvent(props.tracking.GOOGLE_ANALYTICS_4.EVENTS.LOGIN, {
        method: OTT_WEB_METHOD,
      });
      Cookies.remove('logged_in');
    }

    // Triggers on page load but when "_site" is not present
    const bodyTrackEvent = $('body').data('track-event');
    if (bodyTrackEvent && bodyTrackEvent.indexOf('site_') === -1) {
      window.Stats.trackEvent(bodyTrackEvent);
    }

    /**
     * Triggers when user on video page
     */
    if (props.page.PROPERTIES?.VIEW_TYPE === 'video') {
      window.Stats.trackEvent(props.tracking.EVENTS.EVENT_SITE_VIDEO_CLICK);
    }
  };

  const init = () => {
    initTracking();
    bindEvents();
  };

  return {
    init: init,
  };
};

/**
 * This module will be directly imported like "require('/stats')"
 * e.g. here "app/assets/javascripts/admin.js"
 * so initializing it here to avoid calling "init" method in each module
 */
window.Stats = new StatsAdapter(props);

window.addEventListener('load', () => {
  StatsV2().init();
});
