import RedirectException from 'found/lib/RedirectException';

import { setRegistrationType } from '~/apps/Customer/pages/checkout/actions';
import { LOGIN } from '~/apps/Customer/pages/checkout/constants';
import {
  getProcessingTransactionPath,
  getPurchasePath,
  getReceiptPath,
  getSignupPath,
  isCustomerAccessingFVOD,
  isFvod,
  isGift,
} from '~/apps/Customer/pages/checkout/helpers';

export default [
  {
    path: ':sku?',
    getComponent: () => {
      return import('./components/ProductContainer/ProductContainer').then(
        (module) => module.default,
      );
    },
    getData: ({ params }) => {
      return {
        sku: params.sku,
      };
    },
    children: [
      {
        path: 'signup',
        getComponent: () => {
          return import('./components/RightPanel/RightPanel').then(
            (module) => module.default,
          );
        },
        getData: ({ context }) => {
          const { customer, hasPurchasedProduct } =
            context.store.getState().global.current;
          const product = context.store.getState().checkout.product;

          if (
            isCustomerAccessingFVOD(customer, product) ||
            hasPurchasedProduct
          ) {
            throw new RedirectException(getReceiptPath(product));
          }

          if (!isFvod(product)) {
            throw new RedirectException(getPurchasePath(product));
          }
        },
      },
      {
        path: 'login',
        getComponent: () => {
          return import('./components/RightPanel/RightPanel').then(
            (module) => module.default,
          );
        },
        getData: ({ context }) => {
          const { customer, hasPurchasedProduct } =
            context.store.getState().global.current;
          const product = context.store.getState().checkout.product;

          if (
            isCustomerAccessingFVOD(customer, product) ||
            hasPurchasedProduct
          ) {
            throw new RedirectException(getReceiptPath(product));
          }

          context.store.dispatch(setRegistrationType(LOGIN));
          if (!isFvod(product)) {
            throw new RedirectException(getPurchasePath(product));
          }
        },
      },
      {
        path: 'receipt',
        getComponent: () => {
          return import('./components/Receipt/Receipt').then(
            (module) => module.default,
          );
        },
        getData: ({ context }) => {
          const { customer, hasPurchasedProduct, currentPurchaseType } =
            context.store.getState().global.current;
          const { product, purchaseOption } = context.store.getState().checkout;

          if (
            !isGift(purchaseOption) &&
            !isCustomerAccessingFVOD(customer, product) &&
            !hasPurchasedProduct &&
            !currentPurchaseType
          ) {
            throw new RedirectException(
              isFvod(product)
                ? getSignupPath(product)
                : getPurchasePath(product),
            );
          }
        },
      },
      {
        path: 'processing',
        getComponent: () => {
          return import(
            './components/ProcessingTransaction/ProcessingTransaction'
          ).then((module) => module.default);
        },
        getData: ({ context }) => {
          const { customer, preexistingProcessingTransaction } =
            context.store.getState().global.current;
          const { product } = context.store.getState().checkout;

          if (!customer.id) {
            throw new RedirectException(getSignupPath(product));
          }

          if (!preexistingProcessingTransaction.id) {
            throw new RedirectException(getPurchasePath(product));
          }
        },
      },
      {
        path: 'purchase',
        getComponent: () => {
          return import('./components/RightPanel/RightPanel').then(
            (module) => module.default,
          );
        },
        getData: ({ context }) => {
          const {
            customer,
            hasPurchasedProduct,
            currentPurchaseType,
            preexistingProcessingTransaction,
          } = context.store.getState().global.current;
          const product = context.store.getState().checkout.product;

          if (
            isCustomerAccessingFVOD(customer, product) ||
            hasPurchasedProduct ||
            currentPurchaseType
          ) {
            throw new RedirectException(getReceiptPath(product));
          }

          if (isFvod(product)) {
            throw new RedirectException(getSignupPath(product));
          }

          if (preexistingProcessingTransaction.id) {
            throw new RedirectException(getProcessingTransactionPath(product));
          }
        },
      },
      {
        path: 'gift',
        getComponent: () => {
          return import('./components/RightPanel/RightPanel').then(
            (module) => module.default,
          );
        },
        getData: ({ params }) => {
          return {
            sku: params.sku,
          };
        },
      },
    ],
  },
];
